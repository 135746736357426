/**
 * Get a typed key-value from the `.env` file.
 * @param {string} key The name of variable.
 * @returns {string|null} The value of the variable. If the value is empty or not defined, `null` will be returned.
 */
export const getEnvVariable = <T = string>(key: string): T | null => {
	const envVariable = import.meta.env[key] as T
	if (envVariable) {
		return envVariable
	}
	return null
}
