const Hero = (): JSX.Element => {
	return (
		<div className="flex h-screen flex-col items-center justify-center space-y-5 bg-gradient-to-r from-[#09A383] to-[#1CB7DD] px-10 text-center text-white md:px-20">
			<div className=" font-heading text-heading-2 leading-none tracking-tight md:text-heading-1">
				Let&apos;s talk
			</div>
			<div className="mb-3 text-paragraph">
				Take the first step towards enabling a frictionless charging experiences for your charging
				stations or drivers
			</div>
		</div>
	)
}

export default Hero
