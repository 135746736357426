import { Link } from 'react-router-dom'

import LinkedInIcon from '../_icons/LinkedInIcon'
import { FOOTER_LINKS } from './constants'

const Footer = (): JSX.Element => {
	return (
		<footer className=" bg-white px-10 py-8 text-beep-grey md:px-20 md:py-12 lg:py-16">
			<div className="grid grid-cols-1 gap-3 md:grid-cols-4">
				<div className="mb-4 md:col-span-1 md:col-start-1 lg:mb-0">
					<img src="/svg/assets/VoltalityLogo.svg" alt="logo" width={606 / 2.2} height="auto" />
				</div>

				<div className="grid grid-cols-2 gap-y-6 md:col-span-3 md:col-start-2 md:flex md:grid-cols-4 md:justify-around">
					{FOOTER_LINKS.map((column, i) => (
						<div key={i}>
							<div>
								<div className="mb-4 mt-3 text-paragraph font-bold text-beep-black">
									{column.title}
								</div>
								<div className="grid justify-between">
									{column.links.map((link, j) => (
										<Link
											key={j}
											to={link.url}
											className="duration-400 my-2 text-beep-grey no-underline transition-colors ease-in-out hover:text-beep-cyan"
										>
											{link.heading}
										</Link>
									))}
								</div>
							</div>
						</div>
					))}
					<div>
						<div className="mb-6 ml-1 mt-3 text-paragraph font-bold text-beep-black">Follow Us</div>
						<div className="ml-1.5">
							<a
								href="https://linkedin.com/company/voltality"
								className="duration-400 cursor-pointer fill-current text-beep-black transition-colors ease-in-out hover:text-beep-cyan"
								target="_blank"
								rel="noreferrer"
							>
								<LinkedInIcon className="w-5" />
							</a>
						</div>
					</div>
				</div>
			</div>

			<hr className="mb-6 mt-10 border-t border-gray-300" />
			<div className="mb-10">Copyright 2024 Voltality. All rights reserved.</div>
		</footer>
	)
}
export default Footer
