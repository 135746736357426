import { Link } from 'react-router-dom'
import { classNames } from 'src/_shared/utils/elements'
import { HeaderLink } from 'src/_shared/utils/header'

export const Accordion = ({ data, active }: { data: HeaderLink; active: boolean }): JSX.Element => {
	const { url, text } = data
	return (
		<Link to={url}>
			<div
				className={classNames(
					active ? 'visible' : 'collapse',
					'border-grey-100 flex cursor-pointer items-center justify-between overflow-hidden border-b bg-white px-6 py-4 text-center text-base text-paragraph font-medium text-beep-black transition-colors duration-200 hover:text-vol-turquoise'
				)}
			>
				{text}
			</div>
		</Link>
	)
}
