import { lazy, Suspense } from 'react'
import Hero from 'src/pages/AboutUs/components/Hero.tsx'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection.tsx'))
const Growth = lazy(() => import('src/pages/AboutUs/components/Growth.tsx'))
const Investors = lazy(() => import('src/pages/AboutUs/components/Investors.tsx'))
const Partners = lazy(() => import('src/pages/AboutUs/components/Partners.tsx'))

export const AboutUs = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<Growth />
				<Partners />
				<Investors />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
