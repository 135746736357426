import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createBrowserRouter, NonIndexRouteObject, RouterProvider } from 'react-router-dom'
import Layout from 'src/App/Layout.tsx'
import { AboutUs } from 'src/pages/AboutUs'
import { ContactUs } from 'src/pages/ContactUs'
import { Main } from 'src/pages/Main'
import { Media } from 'src/pages/Media'
import { VoltNet } from 'src/pages/VoltNet'
import { VoltNow } from 'src/pages/VoltNow'
import { VoltPos } from 'src/pages/VoltPos'

import { PageRoutePath } from './routing'

type PageRoute = Omit<NonIndexRouteObject, 'path'> & { path: PageRoutePath }

const PAGE_ROUTES: PageRoute[] = [
	{
		element: <AboutUs />,
		path: PageRoutePath.AboutUs
	},
	{
		element: <ContactUs />,
		path: PageRoutePath.ContactUs
	},
	{
		element: <Main />,
		path: PageRoutePath.Main
	},
	{
		element: <Media />,
		path: PageRoutePath.Media
	},
	{
		element: <VoltNet />,
		path: PageRoutePath.VoltNet
	},
	{
		element: <VoltNow />,
		path: PageRoutePath.VoltNow
	},
	{
		element: <VoltPos />,
		path: PageRoutePath.VoltPos
	}
]

const BROWSER_ROUTER = createBrowserRouter(
	PAGE_ROUTES.map((route) => ({
		...route,
		element: <Layout>{route.element}</Layout>
	}))
)

const QUERY_CLIENT = new QueryClient()

const App = (): JSX.Element => {
	return (
		<QueryClientProvider client={QUERY_CLIENT}>
			<RouterProvider router={BROWSER_ROUTER} />
		</QueryClientProvider>
	)
}

export default App
