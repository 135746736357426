import { useCallback } from 'react'
import { NavigateOptions, useLocation, useNavigate, Location } from 'react-router-dom'

export enum PageRoutePath {
	AboutUs = '/about-us',
	ContactUs = '/contact-us',
	Main = '/',
	Media = '/media',
	VoltNet = '/product/voltnet',
	VoltNow = '/product/voltnow',
	VoltPos = '/product/voltpos'
}

interface RouterNavigateFunction {
	(pageRoutePath: PageRoutePath, options?: NavigateOptions): void
	(delta: number): void
}

type RouterLocation<T> = Omit<Location<T>, 'pathname'> & { pathname: PageRoutePath }

/**
 * Returns the current location object, which represents the current URL in web browsers.
 * @returns {RouterLocation<T>} The current location object.
 * @see https://reactrouter.com/hooks/use-location
 */
export const useRouterLocation = <T = null>(): RouterLocation<T> => {
	const location = useLocation() as RouterLocation<T>
	return location
}

/**
 * Returns an imperative function for changing the location. Its underlying behaviour is the same as `react-router-dom`,
 * the only difference is that the arguments are much more strictly-typed to account for `PageRoutePath`.
 * @returns {RouterNavigateFunction} The `navigate` function.
 * @see https://reactrouter.com/hooks/use-navigate
 */
export const useRouterNavigate = (): RouterNavigateFunction => {
	const navigate = useNavigate()

	const routerNavigate: RouterNavigateFunction = useCallback(
		(...args): void => {
			const navigateTo = args[0]
			if (typeof navigateTo === 'number') {
				navigate(navigateTo)
			} else {
				const options = args[1] as NavigateOptions | undefined
				navigate(navigateTo, options)
			}
		},
		[navigate]
	)

	return routerNavigate
}
