import gsap from 'gsap'
import { useEffect, useRef, RefObject } from 'react'

export default (): RefObject<HTMLDivElement> => {
	const ref = useRef<HTMLDivElement>(null)
	const scrollPosition = useRef(0)

	useEffect((): (() => void) => {
		const handleScroll = (): void => {
			const currentPosition = document.documentElement.scrollTop || document.body.scrollTop
			const show = currentPosition <= 0 || currentPosition <= scrollPosition.current

			gsap.to(ref.current, {
				duration: 0.25,
				ease: 'power2.out',
				autoAlpha: show ? 1 : 0,
				rotateX: show ? 0 : 45
			})

			scrollPosition.current = currentPosition
		}

		window.addEventListener('scroll', handleScroll)
		return (): void => { window.removeEventListener('scroll', handleScroll); }
	}, [])

	return ref
}
