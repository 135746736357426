import { ButtonHTMLAttributes, DetailedHTMLProps, MouseEvent, memo } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { ButtonTypes } from '../enums/buttonTypes'
import { classNames } from '../utils/elements'

type ButtonTypeRecord = Record<ButtonTypes, string>

type ButtonProps = {
	linkProps?: {
		to: string
	} & Omit<DetailedHTMLProps<LinkProps, HTMLAnchorElement>, 'to'>
	buttonType?: keyof ButtonTypeRecord
	paddingConfig?: string
	fontSize?: string
	hoverStyle?: string
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const COMMON_BUTTON_STYLE =
	'transition-opacity duration-250 rounded-md text-center disabled:opacity-50 disabled:cursor-not-allowed'
const BUTTON_STYLES: ButtonTypeRecord = {
	[ButtonTypes.Default]: 'bg-gradient-to-r from-vol-teal to-vol-blue text-white',
	[ButtonTypes.BlackSolid]: 'bg-black text-white',
	[ButtonTypes.WhiteGhost]: 'border border-white text-white bg-transparent',
	[ButtonTypes.BlackGhost]: 'border border-black text-black bg-black text-transparent bg-clip-text'
}

const DEFAULT_PADDING_CONFIG = 'py-2 px-5'
const DEFAULT_FONT_SIZE = 'text-md font-semibold'
const DEFAULT_HOVER_STYLE = 'hover:opacity-85'

export const Button = memo(function ButtonRender({
	linkProps: linkProps,
	buttonType = ButtonTypes.Default,
	paddingConfig,
	fontSize,
	hoverStyle,
	...buttonElementProps
}: ButtonProps): JSX.Element {
	const buttonElement = (
		<button
			{...buttonElementProps}
			className={classNames(
				COMMON_BUTTON_STYLE,
				BUTTON_STYLES[buttonType],
				paddingConfig ?? DEFAULT_PADDING_CONFIG,
				fontSize ?? DEFAULT_FONT_SIZE,
				hoverStyle ?? DEFAULT_HOVER_STYLE
			)}
		/>
	)

	if (linkProps) {
		const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>): void => {
			if (buttonElementProps.onClick) {
				event.preventDefault()
			}
			linkProps.onClick?.(event)
		}
		return (
			<Link {...linkProps} onClick={handleLinkClick}>
				{buttonElement}
			</Link>
		)
	}

	return buttonElement
})
