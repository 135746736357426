import { PageRoutePath } from 'src/App/routing.ts'
import { Button } from 'src/_shared/components/Button.tsx'

const Hero = (): JSX.Element => {
	return (
		<div className="relative h-screen">
			{/* Hero Image */}
			<picture>
				<source media="(max-width: 511px)" srcSet="/assets/landing_page/hero/511w_1x.jpg" />
				<source
					media="((min-width: 512px) and (max-width: 1024px))"
					srcSet="/assets/landing_page/hero/1024w_1x.jpg"
				/>
				<img
					loading="lazy"
					src="/assets/landing_page/hero/1440w_1x.jpg"
					alt="Hero image"
					className="absolute inset-0 h-full w-full object-cover"
				/>
			</picture>
			<div className="relative z-10 flex h-full flex-col items-center justify-center px-10 text-center text-white md:px-20">
				<h1 className="mb-6 font-heading text-heading-5 leading-none tracking-tight md:text-heading-3 lg:text-heading-2">
					Making Charging Effortless For Everyone, Together.
				</h1>
				<div className="mb-5 text-paragraph">
					Empowering charging operators & mobility providers to provide better charging experiences
					for drivers
				</div>
				<Button linkProps={{ to: PageRoutePath.VoltNet }}>Learn More</Button>
			</div>
		</div>
	)
}

export default Hero
