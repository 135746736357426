import { PageRoutePath } from 'src/App/routing'

import { FooterSection } from './types'

export const FOOTER_LINKS: FooterSection[] = [
	{
		title: 'Products',
		links: [
			{ heading: 'VoltNET', url: PageRoutePath.VoltNet },
			{ heading: 'VoltNOW', url: PageRoutePath.VoltNow },
			{ heading: 'VoltPOS', url: PageRoutePath.VoltPos }
		]
	},
	{
		title: 'Company',
		links: [{ heading: 'About Us', url: PageRoutePath.AboutUs }]
	},
	{
		title: 'Support',
		links: [{ heading: 'Contact Us', url: PageRoutePath.ContactUs }]
	}
]
