import { extend as addDayjsPlugin } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { Environment } from './_shared/enums/env'
import { getEnvVariable } from './_shared/utils/env'
import './style.css'

const rootElement = document.getElementById('root')
if (rootElement && rootElement.nodeName === 'DIV') {
	const root = createRoot(rootElement)

	const app = ((): JSX.Element => {
		const environment = getEnvVariable<Environment>('VITE_ENVIRONMENT')
		if (environment === Environment.TEST) {
			console.debug(
				[
					`Environment: ${environment}`,
					'<App /> is currently wrapped around <React.StrictMode />',
					'Read More: https://react.dev/reference/react/StrictMode'
				].join('\n')
			)
			return (
				<React.StrictMode>
					<App />
				</React.StrictMode>
			)
		}
		return <App />
	})()

	// Extend Day.js functionalities.
	// Read More: https://day.js.org/docs/en/plugin/plugin
	const loadDayjsPlugins = (): void => {
		addDayjsPlugin(isBetween)
		addDayjsPlugin(isSameOrAfter)
		addDayjsPlugin(isSameOrBefore)
		addDayjsPlugin(isToday)
		addDayjsPlugin(localizedFormat)
	}
	loadDayjsPlugins()

	root.render(app)
} else {
	console.warn('Element div#root was not found in "index.html".')
}
