import gsap from 'gsap'
import { useEffect, useRef } from 'react'

/**
 * @threshold in px
 */
const useScrollFadeIn = ({
	threshold,
	delay = 0,
	active = true
}: {
	threshold?: number
	delay?: number
	active?: boolean
}): ((dom: HTMLElement | SVGSVGElement | null) => void) => {
	const hasPlayedOnce = useRef(false)
	const list = useRef<(HTMLElement | SVGSVGElement)[]>([])

	useEffect(() => {
		if (!hasPlayedOnce.current && active && threshold !== 0) {
			const _threshold = threshold !== undefined ? threshold - window.innerHeight * 0.8 : 0

			gsap.to(list.current, {
				duration: 0,
				opacity: 0
			})

			const handleFadeIn = (): void => {
				const pxNow = document.documentElement.scrollTop || document.body.scrollTop

				if (threshold === undefined || pxNow >= _threshold) {
					gsap.to(list.current, {
						delay,
						duration: 0.5,
						ease: 'power.out',
						opacity: 1,
						stagger: 0.15
					})

					hasPlayedOnce.current = true
					window.removeEventListener('scroll', handleFadeIn)
				}
			}

			handleFadeIn()

			window.addEventListener('scroll', handleFadeIn)
			return (): void => {
				window.removeEventListener('scroll', handleFadeIn)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threshold, active])

	const add = (dom: HTMLElement | SVGSVGElement | null): void => {
		dom && list.current.push(dom)
	}

	list.current = []

	return add
}

export default useScrollFadeIn
