import { HeaderLink } from 'src/_shared/utils/header'

import { Accordion } from './Accordion'
import useDropAnimation from './useDropAnimation'

export const Dropdown = ({
	data,
	active,
	setActive
}: {
	data: HeaderLink[]
	active: boolean
	setActive: (b: boolean) => void
}): JSX.Element => {
	const ref = useDropAnimation(active, setActive)

	return (
		<div
			ref={ref}
			className={'absolute left-0 top-full h-screen w-full origin-top cursor-pointer bg-white'}
		>
			{data.map((header, i) => (
				<Accordion key={i} data={header} active={active} />
			))}
		</div>
	)
}
