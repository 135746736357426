import gsap from 'gsap'
import { useEffect, useRef, RefObject } from 'react'

export default (active: boolean, setActive: (b: boolean) => void): RefObject<HTMLDivElement> => {
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (ref.current) {
			gsap.to(ref.current, {
				duration: 0.25,
				ease: 'power2.out',
				autoAlpha: active ? 1 : 0,
				rotateX: active ? 0 : 45
			})
		}
	}, [active])

	// Clicking on menuburger will collapse the dropdown
	useEffect((): (() => void) => {
		const collapse = (): void => {
			setActive(false)
		}

		window.addEventListener('click', collapse)
		return (): void => {
			window.removeEventListener('click', collapse)
		}
	}, [setActive])

	return ref
}
