import { lazy, Suspense } from 'react'
import Hero from 'src/pages/Main/components/Hero.tsx'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection.tsx'))
const Customers = lazy(() => import('src/pages/Main/components/Customers.tsx'))
const Network = lazy(() => import('src/pages/Main/components/Network.tsx'))
const Partners = lazy(() => import('src/pages/Main/components/Partners.tsx'))
const Services = lazy(() => import('src/pages/Main/components/Services.tsx'))
const Statistics = lazy(() => import('src/pages/Main/components/Statistics.tsx'))

export const Main = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<Partners />
				<Customers />
				<Network />
				<Services />
				<Statistics />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
