import { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { PageRoutePath, useRouterLocation } from 'src/App/routing'
import { Button } from 'src/_shared/components/Button'
import { Dropdown } from 'src/_shared/components/Header/Dropdown'
import { MenuBurger } from 'src/_shared/components/Header/MenuBurger'
import { ButtonTypes } from 'src/_shared/enums/buttonTypes'
import { classNames } from 'src/_shared/utils/elements'
import { HEADER_LINKS } from 'src/_shared/utils/header'
import useScrollFadeIn from 'src/_shared/utils/hooks/useScrollFadeIn'
import useViewportSize from 'src/_shared/utils/hooks/useViewportSize'

import useAnimation from './useAnimation'

interface HeaderProps {
	theme: 'transparent' | 'default'
}

type HeaderThemeConfig = {
	[theme in 'transparent' | 'default']: {
		header: string
		logo: string
		text: string
		button: ButtonTypes
		menu: string
	}
}

const HEADER_THEME_CONFIG: HeaderThemeConfig = {
	transparent: {
		header: 'bg-transparent',
		logo: '/svg/assets/VoltalityLogoWhite.svg',
		text: 'text-white text-paragraph text-base font-medium',
		button: ButtonTypes.WhiteGhost,
		menu: 'white'
	},
	default: {
		header: 'bg-white',
		logo: '/svg/assets/VoltalityLogo.svg',
		text: 'text-beep-grey text-paragraph text-base font-medium',
		button: ButtonTypes.Default,
		menu: 'black'
	}
}

const Header = ({ theme }: HeaderProps): JSX.Element => {
	const threshold = useViewportSize(1200)
	const [expand, setExpand] = useState(false)
	const ref = useAnimation()
	const headerTheme = HEADER_THEME_CONFIG[theme]
	const add = useScrollFadeIn({ delay: 0.25 })
	const location = useRouterLocation()

	// window resize to big -> do not expand
	useEffect(() => {
		setExpand(false)
	}, [threshold])

	const handleMenuClick = (e: React.MouseEvent): void => {
		e.stopPropagation()
		setExpand(!expand)
	}

	// enable scroll when dropdown is collapsed
	useEffect(() => {
		document.body.style.overflow = expand ? 'hidden' : 'unset'
	}, [expand])

	const setExpandCallback = useCallback(setExpand, [setExpand])

	return (
		<header
			ref={ref}
			className={classNames(
				headerTheme.header,
				'absolute z-20 flex w-full items-center justify-between px-6 py-3 sm:px-12 sm:py-5'
			)}
		>
			{/* Logo */}
			<div className="w-36 pr-6 sm:w-auto">
				<Link to={PageRoutePath.Main}>
					<img src={headerTheme.logo} alt="Voltality" width={606 / 3.5} className="h-auto" />
				</Link>
			</div>

			{/* Big window size */}
			{threshold && (
				<>
					<nav className="flex md:mr-6 md:space-x-8 lg:space-x-12 xl:mr-16 xl:space-x-[4.5rem] 2xl:space-x-20">
						{HEADER_LINKS.map((header, i) => (
							<Link
								key={i}
								to={header.url}
								className={classNames(
									headerTheme.text,
									location.pathname === header.url
										? 'underline-animation underline-animation-active'
										: 'underline-animation',
									'duration-400 relative transition-colors ease-in-out'
								)}
							>
								{header.text}
							</Link>
						))}
					</nav>
					<div className="items-end">
						<Button
							linkProps={{ to: PageRoutePath.ContactUs }}
							buttonType={headerTheme.button}
							paddingConfig="px-3 sm:px-4 py-1.5"
							fontSize="text-sm sm:text-md sm:font-semibold"
						>
							Let&apos;s Talk
						</Button>
					</div>
				</>
			)}

			{/* Small window size */}
			{!threshold && (
				<>
					<div className="flex items-center justify-evenly space-x-6">
						<Button
							linkProps={{ to: PageRoutePath.ContactUs }}
							buttonType={headerTheme.button}
							paddingConfig="px-3 sm:px-4 py-1.5"
							fontSize="text-sm sm:text-md sm:font-semibold"
						>
							Let&apos;s Talk
						</Button>
						<MenuBurger
							ref={add}
							color={headerTheme.menu}
							active={expand}
							onClick={handleMenuClick}
						/>
					</div>
					<Dropdown data={HEADER_LINKS} active={expand} setActive={setExpandCallback} />
				</>
			)}
		</header>
	)
}

export default Header
