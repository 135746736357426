import { forwardRef, ForwardedRef } from 'react'

import useAnimation from './useAnimation'

export const MenuBurger = forwardRef(
	(
		{
			color = 'white',
			active,
			...props
		}: {
			color?: string
			active?: boolean
		} & Omit<JSX.IntrinsicElements['svg'], 'ref' | 'style'>,
		ref: ForwardedRef<SVGSVGElement>
	): JSX.Element => {
		const { top, mid, bottom } = useAnimation(active ?? false)

		return (
			<svg
				ref={ref}
				{...props}
				width={23}
				height={19}
				viewBox="0 0 23 19"
				fill="none"
				className="block cursor-pointer"
			>
				<line
					ref={top}
					stroke={color}
					x1="21.4"
					y1="1.6"
					x2="1.6"
					y2="1.6"
					strokeWidth="2.8"
					strokeLinecap="round"
				/>
				<line
					ref={mid}
					stroke={color}
					x1="1.6"
					y1="9.4"
					x2="21.4"
					y2="9.4"
					strokeWidth="2.8"
					strokeLinecap="round"
				/>
				<line
					ref={bottom}
					stroke={color}
					x1="1.6"
					y1="17.4"
					x2="21.4"
					y2="17.4"
					strokeWidth="2.8"
					strokeLinecap="round"
				/>
			</svg>
		)
	}
)

MenuBurger.displayName = 'MenuBurger'
