import { lazy, Suspense } from 'react'
import Hero from 'src/pages/ContactUs/components/Hero.tsx'

const Contact = lazy(() => import('src/pages/ContactUs/components/Contact.tsx'))
const Form = lazy(() => import('src/pages/ContactUs/components/Form.tsx'))

export const ContactUs = (): JSX.Element => {
	return (
		<div>
			<Hero />
			<Suspense fallback={<></>}>
				<div className="relative flex flex-col">
					<Contact />
					<Form />
				</div>
			</Suspense>
		</div>
	)
}
