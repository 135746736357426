import { ReactNode, useEffect } from 'react'
import Footer from 'src/_shared/components/Footer'
import Header from 'src/_shared/components/Header'

interface LayoutProps {
	children: ReactNode
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
	/**
	 * Hacky workaround so that a newly rendered screen entered via `Link` or `navigate` always starts from the top.
	 * This is used in place of `ScrollRestoration` to prevent lottie animation from playing when intersection is observed(upon auto-scrolling).
	 */
	useEffect((): void => {
		window.scrollTo({ top: 0, behavior: 'instant' })
	}, [children])
	return (
		<div>
			<Header theme="transparent" />
			{children}
			<Footer />
		</div>
	)
}

export default Layout
