import { lazy, Suspense } from 'react'
import Hero from 'src/pages/VoltNow/components/Hero.tsx'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection.tsx'))
const ChargingFlow = lazy(() => import('src/pages/VoltNow/components/ChargingFlow.tsx'))
const Compatibility = lazy(() => import('src/_shared/components/Compatibility.tsx'))
const Dashboard = lazy(() => import('src/pages/VoltNow/components/Dashboard.tsx'))

export const VoltNow = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<ChargingFlow />
				<Dashboard />
				<Compatibility page="VoltNow" />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
