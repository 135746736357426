import { useState, useEffect } from 'react'

const useViewportSize = (threshold: number): boolean => {
	const [isLarge, setIsLarge] = useState<boolean>(window.innerWidth >= threshold)

	useEffect(() => {
		const handleResize = (): void => {
			const width = window.innerWidth
			setIsLarge(width >= threshold)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [threshold])

	return isLarge
}

export default useViewportSize
