import { SVGProps, memo } from 'react'

type VoltPosLogoProps = SVGProps<SVGSVGElement>

const VoltPosLogo = ({
	viewBox = '0 0 611 117',
	xmlns = 'http://www.w3.org/2000/svg',
	fill = 'none',
	...props
}: VoltPosLogoProps): JSX.Element => (
	<svg viewBox={viewBox} fill={fill} xmlns={xmlns} {...props}>
		<path
			d="M291.209 92.8354V95.5225C291.209 97.3143 290.497 99.0327 289.231 100.3C287.964 101.567 286.245 102.278 284.453 102.278H245.069C240.633 102.283 236.255 101.268 232.274 99.3099C227.44 96.8867 223.369 93.1777 220.506 88.5907C217.644 84.0036 216.102 78.7162 216.049 73.3096V18.0336C216.049 16.235 216.764 14.5101 218.035 13.2383C219.307 11.9665 221.032 11.252 222.831 11.252H225.518C227.312 11.2587 229.03 11.9762 230.296 13.2473C231.563 14.5183 232.274 16.2394 232.274 18.0336V74.9218C232.654 77.7448 233.952 80.3643 235.969 82.3762C237.985 84.388 240.607 85.6804 243.431 86.0538H284.377C285.271 86.0471 286.159 86.2175 286.987 86.5553C287.816 86.8931 288.57 87.3916 289.205 88.022C289.84 88.6524 290.344 89.4022 290.688 90.2284C291.032 91.0545 291.209 91.9405 291.209 92.8354Z"
			fill="#006B8C"
		/>
		<path
			d="M154.657 11.252H132.853C120.772 11.252 109.186 16.0511 100.644 24.5937C92.1011 33.1363 87.3019 44.7226 87.3019 56.8036C87.3019 68.8846 92.1011 80.4708 100.644 89.0134C109.186 97.556 120.772 102.355 132.853 102.355H154.657C166.738 102.355 178.324 97.556 186.867 89.0134C195.409 80.4708 200.208 68.8846 200.208 56.8036C200.208 44.7226 195.409 33.1363 186.867 24.5937C178.324 16.0511 166.738 11.252 154.657 11.252ZM154.657 86.105H132.853C125.229 85.8797 117.992 82.6925 112.678 77.2196C107.365 71.7467 104.393 64.4188 104.393 56.7908C104.393 49.1628 107.365 41.8349 112.678 36.362C117.992 30.8891 125.229 27.7018 132.853 27.4765H154.657C162.281 27.7018 169.518 30.8891 174.832 36.362C180.145 41.8349 183.118 49.1628 183.118 56.7908C183.118 64.4188 180.145 71.7467 174.832 77.2196C169.518 82.6925 162.281 85.8797 154.657 86.105Z"
			fill="#006B8C"
		/>
		<path
			d="M370.157 18.0338V20.7976C370.157 22.5894 369.445 24.3078 368.178 25.5748C366.911 26.8418 365.193 27.5536 363.401 27.5536H332.692V95.5227C332.692 97.3169 331.981 99.0379 330.715 100.309C329.448 101.58 327.73 102.297 325.936 102.304H323.198C321.404 102.297 319.685 101.58 318.419 100.309C317.153 99.0379 316.442 97.3169 316.442 95.5227V27.5023H285.733C283.941 27.5023 282.223 26.7906 280.956 25.5236C279.689 24.2566 278.977 22.5382 278.977 20.7464V18.0338C278.977 16.2396 279.688 14.5185 280.954 13.2475C282.22 11.9764 283.939 11.259 285.733 11.2522H363.247C364.149 11.2352 365.044 11.398 365.882 11.7312C366.719 12.0644 367.482 12.5612 368.125 13.1927C368.769 13.8241 369.28 14.5774 369.629 15.4087C369.977 16.2399 370.157 17.1324 370.157 18.0338Z"
			fill="#006B8C"
		/>
		<path
			d="M32.0259 11.4824L22.9156 43.8292C22.8065 44.217 22.8021 44.6269 22.903 45.0169C23.004 45.4069 23.2066 45.7632 23.4902 46.0494C23.7737 46.3355 24.1281 46.5414 24.5172 46.6459C24.9063 46.7504 25.3162 46.7498 25.705 46.6442L42.339 41.9355L33.5358 82.0107C33.4885 82.2804 33.5389 82.5581 33.6779 82.794C33.8169 83.0299 34.0354 83.2086 34.2942 83.298C34.5531 83.3873 34.8353 83.3815 35.0902 83.2816C35.3451 83.1817 35.5562 82.9942 35.6854 82.7528L64.1423 24.892C64.3328 24.5052 64.4096 24.0722 64.3636 23.6435C64.3177 23.2148 64.151 22.8078 63.883 22.4701C63.6149 22.1324 63.2565 21.8777 62.8494 21.7356C62.4423 21.5935 62.0032 21.5699 61.5832 21.6676L46.4847 25.9157L50.5025 11.5593H76.9377C78.0902 11.5621 79.2227 11.8597 80.2277 12.4238C81.2327 12.9879 82.0766 13.7997 82.6793 14.7819C83.282 15.7642 83.6234 16.8844 83.6711 18.0358C83.7187 19.1873 83.471 20.3318 82.9516 21.3605L47.841 98.875C47.2759 99.9765 46.4179 100.901 45.3614 101.546C44.3048 102.192 43.0908 102.533 41.8528 102.533C40.6147 102.533 39.4007 102.192 38.3442 101.546C37.2877 100.901 36.4297 99.9765 35.8645 98.875L0.728362 21.2837C0.207296 20.2568 -0.0415329 19.1133 0.00565341 17.9627C0.0528397 16.812 0.394469 15.6927 0.997874 14.7119C1.60128 13.731 2.44629 12.9214 3.45203 12.3605C4.45777 11.7995 5.59062 11.506 6.74221 11.508C6.74221 11.508 32.0515 11.508 32.0259 11.4824Z"
			fill="#006B8C"
		/>
		<rect
			x="376.906"
			y="10.7598"
			width="234.094"
			height="91.2819"
			rx="45.641"
			fill="url(#paint0_linear_1_1130)"
		/>
		<path
			d="M420.814 26.638H437.498C443.948 26.638 448.42 27.326 452.634 31.024C457.278 35.152 458.482 40.742 458.482 44.87C458.482 47.106 458.138 53.298 453.752 57.684C450.57 60.866 445.84 62.758 439.218 62.758H431.994V84H420.814V26.638ZM431.994 36.27V53.126H436.552C438.702 53.126 441.454 53.126 443.862 51.32C445.926 49.686 446.958 47.106 446.958 44.612C446.958 41.43 445.41 39.28 443.776 38.076C441.454 36.442 438.53 36.27 435.864 36.27H431.994ZM494.617 25.348C512.677 25.348 525.233 37.904 525.233 55.362C525.233 72.82 512.677 85.29 494.617 85.29C476.557 85.29 464.001 72.82 464.001 55.362C464.001 37.904 476.557 25.348 494.617 25.348ZM494.617 75.142C505.797 75.142 513.709 66.972 513.709 55.362C513.709 43.752 505.797 35.496 494.617 35.496C483.437 35.496 475.525 43.752 475.525 55.362C475.525 66.972 483.437 75.142 494.617 75.142ZM569.592 32.056L563.744 39.71C560.39 35.238 556.434 34.808 554.37 34.808C548.35 34.808 546.458 38.592 546.458 41.516C546.458 42.892 546.888 44.182 548.264 45.386C549.64 46.676 551.532 47.364 555.144 48.654C559.616 50.202 563.83 51.75 566.754 54.416C569.334 56.738 571.656 60.608 571.656 66.456C571.656 77.636 563.572 85.29 551.704 85.29C541.126 85.29 534.934 78.84 531.924 74.454L539.234 67.488C541.986 73.078 547.232 75.142 551.274 75.142C556.348 75.142 560.132 72.046 560.132 67.23C560.132 65.166 559.444 63.532 557.896 62.07C555.746 60.092 552.306 58.974 549.038 57.856C546.028 56.824 542.416 55.448 539.492 52.868C537.6 51.234 534.934 48.052 534.934 42.204C534.934 33.776 540.61 25.348 553.338 25.348C556.95 25.348 563.744 26.036 569.592 32.056Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_1130"
				x1="376.906"
				y1="56.4007"
				x2="611"
				y2="56.4007"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#00974A" />
				<stop offset="1" stopColor="#1EB9E7" />
			</linearGradient>
		</defs>
	</svg>
)

const MemoisedVoltPosLogo = memo(VoltPosLogo)

export default MemoisedVoltPosLogo
