import gsap from 'gsap'
import { useEffect, useRef } from 'react'

const defaults = {
	duration: 0.25,
	ease: 'power2.out',
	overwrite: true
}

const useAnimation = (
	active: boolean
): {
	top: React.RefObject<SVGLineElement>
	mid: React.RefObject<SVGLineElement>
	bottom: React.RefObject<SVGLineElement>
} => {
	const top = useRef<SVGLineElement>(null)
	const mid = useRef<SVGLineElement>(null)
	const bottom = useRef<SVGLineElement>(null)

	useEffect(() => {
		gsap.to(top.current, {
			...defaults,
			rotateZ: active ? 45 : 0,
			x: active ? 1 : 0,
			y: active ? 1 : 0
		})

		gsap.to(mid.current, {
			...defaults,
			opacity: active ? 0 : 1
		})

		gsap.to(bottom.current, {
			...defaults,
			rotateZ: active ? -45 : 0,
			x: active ? 1 : 0,
			y: active ? -1 : 0
		})
	}, [active])

	return { top, mid, bottom }
}

export default useAnimation
