import { lazy, Suspense } from 'react'
import Hero from 'src/pages/VoltPos/components/Hero.tsx'

const CallToActionSection = lazy(() => import('src/_shared/components/CallToActionSection.tsx'))
const Compatibility = lazy(() => import('src/_shared/components/Compatibility.tsx'))
const CustomerSatisfaction = lazy(
	() => import('src/pages/VoltPos/components/CustomerSatisfaction.tsx')
)
const Features = lazy(() => import('src/pages/VoltPos/components/Features.tsx'))
const Payments = lazy(() => import('src/pages/VoltPos/components/Payments.tsx'))

export const VoltPos = (): JSX.Element => {
	return (
		<>
			<Hero />
			<Suspense fallback={<></>}>
				<Features />
				<Compatibility page="VoltPos" />
				<Payments />
				<CustomerSatisfaction />
				<CallToActionSection />
			</Suspense>
		</>
	)
}
